import React from "react";
import ScheduledInfo from "./ScheduledInfo";
import submittedIcon from "../../assets/submittedIcon.svg";
import config from "../../config";
export default function SubmittedBox({
  setStep,
  dateSelected,
  timeSelected,
  timeZoneSelected,
}) {
  return (
    <div className="submitted-box-overlay">
      <div className="submitted-box">
        <ScheduledInfo
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          timeZoneSelected={timeZoneSelected}
        >
          <img src={submittedIcon} alt="Check Icon" />
        </ScheduledInfo>
        <div className="submitted-message">
          <h2>Demo Successfully Scheduled!</h2>
          <p>
            Our demo has been successfully booked. We look forward to showcasing
            how Akira AI can transform your business.
          </p>
          <a href={config.info.REACT_APP_WEBSITE_URL}>
            <button className="done-button">
              <span>Done</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
