import "./FormComponents.css";

export const FormButtons = ({ children }) => {
  return <div className="buttons-wrapper">{children}</div>;
};

export const CancelButton = ({ name, onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <span>{name}</span>
    </button>
  );
};

export const SubmitButton = ({ name, boolVar = true, onClick }) => {
  return (
    <button
      className="submit-button"
      onClick={onClick}
      style={{
        cursor: boolVar ? "pointer" : "not-allowed",
        background: boolVar
          ? "linear-gradient(274.7deg, #dd01ee 27.81%, #4501fa 103.1%)"
          : "linear-gradient(274.7deg, #dd01ee4f 27.81%, #4501fa4f 103.1%)",
      }}
    >
      <span>{name}</span>
    </button>
  );
};
